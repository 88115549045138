import React, { useState, useEffect } from 'react';
// import ControlledPopup from '../ControlledPopup';
import FilterContainer from './FilterContainer';
import { NumericFormat } from 'react-number-format';

import {
  HOUSE,
  CONDO,
  TOWNHOUSE,
  LAND,
  FULLY_FURNISHED,
  PARTIALLY_FURNISHED,
  UNFURNISHED,
  MONTHLY_CONTRACT,
  YEARLY_CONTRACT,
} from '../../../constants/constant';
import {
  PropertyType,
  FurnishingStatusType,
  ContractType,
  MarketFilter,
} from '../../../types/types';
import FilterCheckbox from './FilterCheckbox';
import FilterDropdown from './FilterDropdown';
import { useInternationalization } from '../../../contexts/internationalization';
import { useProperty } from '../../../contexts/property';
import { useGlobal } from '../../../contexts/global';
import RadioBtn from './RadioBtn';
import { featuresList } from '../../../data/data';

import { SellerFinance } from '../../steps/propertySteps/SelectSpecifics';
import FilterSearchBtn from './FilterSearchBtn';
import { initialFilter } from '../../../initialStates/initialStates';
import { useTranslation } from 'react-i18next';
import LocationSearch from '../../steps/propertySteps/Location/LocationSearch';
import { getUserLocalisationByIP } from '../../../utils/getCountry';

const MarketFilter3 = ({ setIsFilterOpen }: any) => {
  const { t } = useTranslation();
  const { currency } = useInternationalization();
  // const [tempFilter, setTempFilter] = useState(initialFilter);
  const {
    filter,
    count,
    fetchCount,
    debouncedFetchProperties,
    fetchAllPropertiesForMap,
    setFilter,
    tempFilter,
    setTempFilter,
    showMap,
    setCoordinates,
    coordinates,
    properties,
  } = useProperty(); //fetchMaxListingPrice
  const { keyword, mainColor } = useGlobal();

  const [selectedPropertyTypes, setSelectedPropertyTypes] = useState<PropertyType[]>([
    HOUSE,
    CONDO,
    TOWNHOUSE,
    LAND,
  ]);
  const [selectedFurnishingStatus, setSelectedFurnishingStatus] = useState<FurnishingStatusType[]>([
    FULLY_FURNISHED,
    PARTIALLY_FURNISHED,
    UNFURNISHED,
  ]);
  const [selectedFrequency, setSelectedFrequency] = useState<ContractType[] | string[]>([
    MONTHLY_CONTRACT,
    YEARLY_CONTRACT,
  ]);
  const [selectedFeatures, setSelectedFeatures] = useState<string[]>([]);
  const [selectedRadius, setSelectedRadius] = useState<number | ''>('');

  const radiusOptions = [
    { label: t('radius'), value: '' },
    { label: `1 ${t('km')}`, value: 1 },
    { label: `2 ${t('km')}`, value: 2 },
    { label: `3 ${t('km')}`, value: 3 },
    { label: `5 ${t('km')}`, value: 5 },
    { label: `10 ${t('km')}`, value: 10 },
    { label: `20 ${t('km')}`, value: 20 },
  ];

  const initialFilterState = {
    isPropertyTypeOpen: false,
    isPriceOpen: false,
    isBedAndBathOpen: false,
    isFeaturesOpen: false,
    isFurnishingStatusOpen: false,
    isFrequencyOpen: false,
    // isLocationOpen: false,
    // isSortByOpen: false,
  };

  const [filterState, setFilterState] = useState(initialFilterState);
  const [locationString, setLocationString] = useState<any>(''); //null
  const [formattedMainText, setFormattedMainText] = useState<string>('');
  const [locationObject, setLocationObject] = useState<any>({
    city: '',
    country: '',
    latitude: '',
    longitude: '',
  });

  // const handleLocationChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const seachedLoc = e.target.value;

  //   setTempFilter({ ...tempFilter, city: seachedLoc });

  //   // get lat long and set coordinates
  //   const response = await fetch(
  //     `https://maps.googleapis.com/maps/api/geocode/json?address=${seachedLoc}&key=${process.env.REACT_APP_GOOGLEMAP_WEBSERVICE_API_KEY}`
  //   );
  //   const data = await response.json();

  //   const loc = data.results[0].geometry.location;

  //   setCoordinates({ lat: loc.lat, lng: loc.lng });
  // };

  const handleRadiusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = Number(e.target.value);
    setSelectedRadius(value);
    setTempFilter({ ...tempFilter, radius: value });
  };

  const handleFinanceCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTempFilter({ ...tempFilter, hasSellerFinance: e.target.checked });
  };

  const handleDailyCheckboxChange = () => {
    if (!tempFilter?.hasDailyRental) {
      setSelectedFrequency([]);
    } else {
      setSelectedFrequency([MONTHLY_CONTRACT, YEARLY_CONTRACT]);
    }
    setTempFilter({ ...tempFilter, hasDailyRental: !tempFilter?.hasDailyRental });
  };

  const handleCheckboxChange = <T,>(
    item: T,
    setSelectedArray: React.Dispatch<React.SetStateAction<T[]>>,
    selectedArray: T[]
  ) => {
    const isSelected = selectedArray.includes(item);

    if (isSelected) {
      setSelectedArray(selectedArray.filter((selectedItem) => selectedItem !== item));
    } else {
      setSelectedArray([...selectedArray, item]);
    }
  };

  const handleTypeCheckboxChange = (type: PropertyType) => {
    handleCheckboxChange(type, setSelectedPropertyTypes, selectedPropertyTypes);
  };

  const handleFrequencyCheckboxChange = (freq: ContractType) => {
    handleCheckboxChange(freq, setSelectedFrequency, selectedFrequency);
  };

  const handleFeatureCheckboxChange = (feature: string) => {
    handleCheckboxChange(feature, setSelectedFeatures, selectedFeatures);
  };

  const handleFurnishingStatusCheckboxChange = (status: FurnishingStatusType) => {
    handleCheckboxChange(status, setSelectedFurnishingStatus, selectedFurnishingStatus);
  };

  const resetFilter = () => {
    const emptyFilterWithKeyword: MarketFilter = {
      ...initialFilter,
      listingPrice: ['', ''],
      keyword: keyword,
      hasSellerFinance: false,
      businessId: tempFilter?.businessId, // do not reset businessId in case it is a WL
    };
    setSelectedFeatures([]);
    setSelectedPropertyTypes([HOUSE, CONDO, TOWNHOUSE, LAND]);
    setSelectedRadius('');
    setLocationString('');
    setTempFilter(emptyFilterWithKeyword);
    // handleSearch(1);
  };

  const toggleDropdown = (dropdownKey: keyof typeof filterState) => {
    setFilterState((prevState) => {
      // Create a new state object with all dropdowns set to false
      const newState = {
        isPropertyTypeOpen: false,
        isPriceOpen: false,
        isBedAndBathOpen: false,
        isFeaturesOpen: false,
        isFurnishingStatusOpen: false,
        isFrequencyOpen: false,
      };

      // Set the specified dropdown to true
      newState[dropdownKey] = !prevState[dropdownKey];

      return newState;
    });
  };

  const handleOptionChange = (option: string) => {
    switch (option) {
      case 'rent':
        setTempFilter({
          ...tempFilter,
          isForRent: true,
          isForSale: false,
          isOffPlan: false,
          hasSellerFinance: false,
        });
        break;
      case 'buy':
        setTempFilter({ ...tempFilter, isForRent: false, isForSale: true, isOffPlan: false });
        break;
      case 'offPlan':
        setTempFilter({ ...tempFilter, isForRent: false, isForSale: false, isOffPlan: true });
        break;
      default:
        break;
    }
  };

  const handleShowResults = () => {
    if (showMap) {
      fetchAllPropertiesForMap(tempFilter);
    } else {
      debouncedFetchProperties({ ...tempFilter, keyword: keyword }, 1, properties);
    }

    setFilter({ ...tempFilter, order: filter?.order }); // the temporary filter become the filter for scrolling
  };

  useEffect(() => {
    // reset filter when unmount
    return () => {
      resetFilter();
      setFilter({
        ...initialFilter,
        listingPrice: ['', ''],
        keyword: keyword,
        hasSellerFinance: false,
        businessId: tempFilter?.businessId,
      });
    };
  }, []);

  useEffect(() => {
    fetchCount(tempFilter);
  }, [tempFilter]);

  useEffect(() => {
    console.log('locationObject', locationObject);
    console.log('formattedMainText', formattedMainText);

    if (locationObject.latitude) {
      setTempFilter({
        ...tempFilter,
        city: formattedMainText, // using the formattedMainText to search for the city
        country: locationObject.country,
        centerLat: locationObject.latitude,
        centerLon: locationObject.longitude,
      });
      setCoordinates({ lat: locationObject.latitude, lng: locationObject.longitude });
    } else {
      const setLoc = async () => {
        if (!coordinates.lat) {
          const localisation = await getUserLocalisationByIP(); // call extrem IP API
          setCoordinates({ lat: Number(localisation.lat), lng: Number(localisation.lon) });
          setTempFilter({
            ...tempFilter,
            country: locationObject.country,
          });
        }
      };
      setLoc();
    }
  }, [formattedMainText, locationObject]);

  useEffect(() => {
    setTempFilter({ ...tempFilter, propertyTypes: selectedPropertyTypes });
  }, [selectedPropertyTypes]);

  useEffect(() => {
    setTempFilter({ ...tempFilter, features: selectedFeatures });
  }, [selectedFeatures]);

  useEffect(() => {
    setTempFilter({ ...tempFilter, furnishingStatus: selectedFurnishingStatus });
  }, [selectedFurnishingStatus]);

  useEffect(() => {
    if (selectedFrequency.length > 0) {
      setTempFilter({ ...tempFilter, frequency: selectedFrequency, hasDailyRental: false });
    } else {
      setTempFilter({ ...tempFilter, frequency: selectedFrequency, hasDailyRental: true });
    }
  }, [selectedFrequency]);

  useEffect(() => {
    setFilter({ ...filter, currency, keyword });
    setTempFilter({ ...tempFilter, currency, keyword });

    if (showMap) {
      fetchAllPropertiesForMap({ ...filter, currency });
    } else {
      debouncedFetchProperties({ ...filter, currency, keyword }, 1, properties);
    }
  }, [currency, filter?.order, keyword, showMap]);

  return (
    <FilterContainer setIsFilterOpen={setIsFilterOpen} title="Market filters">
      <div
        className="scroller sm:relative h-[calc(100vh-7rem)]"
        style={{ '--main-color': mainColor } as React.CSSProperties} // Type assertion
      >
        <div className="h-12 bg-white ml-2 mr-4 pl-2 flex items-center border border-gray-300 focus:border-deehiy rounded-xl ">
          {/* <img alt="pin" src="/images/icons/pin-card.svg" className="w-5 h-5 " /> */}
          {/* <input
            className="focus:ring-0 w-full group placeholder-placeholderText placeholder:text-sm border-none outline-none text-sm"
            value={tempFilter?.city}
            autoFocus={false}
            onChange={handleLocationChange}
            placeholder={t('Search location')}
          /> */}
          <LocationSearch
            locationString={locationString}
            setLocationString={setLocationString}
            setLocationObject={setLocationObject}
            setFormattedMainText={setFormattedMainText}
            label=""
          />
          <div className="text-deehiy bg-deehiy-veryLight font-bold mr-1 w-20 rounded-xl">
            <select
              id="radius-select"
              value={selectedRadius}
              onChange={handleRadiusChange}
              className={` text-sm rounded-xl cursor-pointer outline-none ring-0 focus:ring-0 focus:outline-none no-arrow `}
            >
              {/* <option value="">radius</option> */}
              {radiusOptions.map((option) => (
                <option
                  className="text-defaultText bg-white"
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="h-12 bg-white ml-2 mt-2 mr-4 pl-2 py-1 flex items-center border text-gray-800 border-gray-300 rounded-xl justify-around ">
          <button
            className={`h-full w-full px-1 rounded-xl text-center  items-center ${
              tempFilter?.isForRent ? 'text-deehiy bg-deehiy-veryLight font-bold' : 'semibold'
            }`}
            onClick={() => handleOptionChange('rent')}
          >
            {t('Rent')}
          </button>
          <button
            className={`h-full w-full px-1 rounded-xl text-center  items-center ${
              tempFilter?.isForSale ? 'text-deehiy bg-deehiy-veryLight font-bold' : 'semibold'
            }`}
            onClick={() => handleOptionChange('buy')}
          >
            {t('Buy')}
          </button>
          <button
            className={`h-full w-full px-1 rounded-xl text-center  items-center ${
              tempFilter?.isOffPlan ? 'text-deehiy bg-deehiy-veryLight font-bold' : 'semibold'
            }`}
            onClick={() => handleOptionChange('offPlan')}
          >
            {t('Off-plan')}
          </button>
        </div>

        <div className="flex w-full justify-center">
          {/* <ControlledPopup
            Trigger={() => <div></div>}
            title={'Search property'}
            text={'filter the listings to find what you want'}
            isOpen={true}
          /> */}
        </div>

        <FilterDropdown
          label={t('Property type')}
          sectionHeight={150}
          isOpen={filterState.isPropertyTypeOpen}
          setIsOpen={() => toggleDropdown('isPropertyTypeOpen')}
        >
          <>
            <FilterCheckbox
              title={t('House / Villa')}
              label={t('House / Villa')}
              isChecked={selectedPropertyTypes.includes(HOUSE)}
              check={() => handleTypeCheckboxChange(HOUSE)}
            />
            <FilterCheckbox
              title={t('Townhouse / Shophouse')}
              label={t('Townhouse / Shophouse')}
              isChecked={selectedPropertyTypes.includes(TOWNHOUSE)}
              check={() => handleTypeCheckboxChange(TOWNHOUSE)}
            />
            <FilterCheckbox
              title={t('Condo / Apartment')}
              label={t('Condo / Apartment')}
              isChecked={selectedPropertyTypes.includes(CONDO)}
              check={() => handleTypeCheckboxChange(CONDO)}
            />
            <FilterCheckbox
              title={t('Land')}
              label={t('Land')}
              isChecked={selectedPropertyTypes.includes(LAND)}
              check={() => handleTypeCheckboxChange(LAND)}
            />
          </>
        </FilterDropdown>

        <FilterDropdown
          label={t('Furnishing status')}
          sectionHeight={110}
          isOpen={filterState.isFurnishingStatusOpen}
          setIsOpen={() => toggleDropdown('isFurnishingStatusOpen')}
        >
          <>
            <FilterCheckbox
              title={t('Fully furnished')}
              label={t('Fully furnished')}
              isChecked={selectedFurnishingStatus.includes(FULLY_FURNISHED)}
              check={() => handleFurnishingStatusCheckboxChange(FULLY_FURNISHED)}
            />
            <FilterCheckbox
              title={t('Partially furnished')}
              label={t('Partially furnished')}
              isChecked={selectedFurnishingStatus.includes(PARTIALLY_FURNISHED)}
              check={() => handleFurnishingStatusCheckboxChange(PARTIALLY_FURNISHED)}
            />
            <FilterCheckbox
              title={t('Unfurnished')}
              label={t('Unfurnished')}
              isChecked={selectedFurnishingStatus.includes(UNFURNISHED)}
              check={() => handleFurnishingStatusCheckboxChange(UNFURNISHED)}
            />
          </>
        </FilterDropdown>

        <FilterDropdown
          label={t('Beds & Baths')}
          sectionHeight={160}
          isOpen={filterState.isBedAndBathOpen}
          setIsOpen={() => toggleDropdown('isBedAndBathOpen')}
        >
          <div className="">
            <div className="text-xs text-placeholderText pl-2 pb-1">{t('Beds')}</div>
            <RadioBtn category={'bedrooms'} filter={tempFilter} setFilter={setTempFilter} max={6} />
            <div className="text-xs mt-6 text-placeholderText pl-2 pb-1">{t('Baths')}</div>
            <RadioBtn
              category={'bathrooms'}
              filter={tempFilter}
              setFilter={setTempFilter}
              max={6}
            />
          </div>
        </FilterDropdown>

        <FilterDropdown
          sectionHeight={110}
          label={`${t('Price')} (${currency}${tempFilter?.isForRent ? ` / ${t('month')}` : ''})`}
          isOpen={filterState.isPriceOpen}
          setIsOpen={() => toggleDropdown('isPriceOpen')}
        >
          <>
            <div className="grid grid-cols-2 gap-x-4 pb-3">
              <NumericFormat
                className="focus:ring-0 text-right border-gray-300 focus:border-deehiy rounded-xl text-sm"
                title={t('min price')}
                onChange={(e) => {
                  // eslint-disable-next-line
                  tempFilter?.listingPrice &&
                    setTempFilter({
                      ...tempFilter,
                      listingPrice: [
                        e.target.value.length !== 0 ? e.target.value.replace(/ /g, '') : '',
                        tempFilter?.listingPrice[1]!,
                      ],
                    });
                }}
                value={
                  tempFilter?.listingPrice && tempFilter?.listingPrice[0].length !== 0
                    ? Number(tempFilter?.listingPrice![0])
                    : ''
                }
                thousandSeparator=" "
                placeholder={t('min price')}
              />
              {/* <input
            title="max price"
            className="border text-center focus:border-deehiy outline-none"
            onChange={(e) => {
              // eslint-disable-next-line
              filter?.listingPrice &&
                setFilter({
                  ...filter,
                  listingPrice: [filter?.listingPrice[0]!, Number(e.target.value)],
                });
            }}
            value={filter?.listingPrice![1]}
          /> */}
              <NumericFormat
                className="focus:ring-0 text-right border-gray-300 focus:border-deehiy rounded-xl text-sm"
                title={t('max price')}
                onChange={(e) => {
                  // if (Number(e.target.value) === 0 || e.target.value === undefined) {
                  //   fetchMaxListingPrice(currency);
                  //   return;
                  // }
                  // eslint-disable-next-line
                  tempFilter?.listingPrice &&
                    setTempFilter({
                      ...tempFilter,
                      listingPrice: [
                        tempFilter?.listingPrice[0],
                        e.target.value.length !== 0 ? e.target.value.replace(/ /g, '') : '',
                      ],
                    });
                }}
                value={
                  tempFilter?.listingPrice && tempFilter?.listingPrice[1].length !== 0
                    ? Number(tempFilter?.listingPrice[1])
                    : ''
                }
                thousandSeparator=" "
                placeholder={t('max price')}
              />
            </div>
            {!tempFilter?.isForRent && (
              <SellerFinance
                handleChange={handleFinanceCheckboxChange}
                value={tempFilter?.hasSellerFinance}
              />
            )}
          </>
        </FilterDropdown>

        <FilterDropdown
          label={t('Features')}
          sectionHeight={250}
          isOpen={filterState.isFeaturesOpen}
          setIsOpen={() => toggleDropdown('isFeaturesOpen')}
        >
          <div>
            <div className="border border-transparent border-b-gray-100 ">
              <div className="pt-2 pl-2 italic">{t('Key features')}</div>
              {featuresList.map((feat) => {
                if (feat.category === 'basic') {
                  return (
                    <FilterCheckbox
                      title={t(feat.title)}
                      label={t(feat.title)}
                      Icon={feat.SVG}
                      isChecked={selectedFeatures.includes(feat.title)}
                      check={() => handleFeatureCheckboxChange(feat.title)}
                    />
                  );
                }
              })}
            </div>
            <div className="border border-transparent border-b-gray-100">
              <div className="pt-2 pl-2 italic">{t('Amenities')}</div>
              {featuresList.map((feat) => {
                if (feat.category === 'standoutAmenities') {
                  return (
                    <FilterCheckbox
                      title={t(feat.title)}
                      label={t(feat.title)}
                      Icon={feat.SVG}
                      isChecked={selectedFeatures.includes(feat.title)}
                      check={() => handleFeatureCheckboxChange(feat.title)}
                    />
                  );
                }
              })}
            </div>
            <div className="border border-transparent border-b-gray-100">
              <div className="pt-2 pl-2 italic">{t('View')}</div>
              {featuresList.map((feat) => {
                if (feat.category === 'views') {
                  return (
                    <FilterCheckbox
                      title={t(feat.title)}
                      label={t(feat.title)}
                      Icon={feat.SVG}
                      isChecked={selectedFeatures.includes(feat.title)}
                      check={() => handleFeatureCheckboxChange(feat.title)}
                    />
                  );
                }
              })}
            </div>
            <div className="border border-transparent border-b-gray-100 ">
              <div className="pt-2 pl-2 italic">{t('Safety items')}</div>
              {featuresList.map((feat) => {
                if (feat.category === 'security') {
                  return (
                    <FilterCheckbox
                      title={t(feat.title)}
                      label={t(feat.title)}
                      Icon={feat.SVG}
                      isChecked={selectedFeatures.includes(feat.title)}
                      check={() => handleFeatureCheckboxChange(feat.title)}
                    />
                  );
                }
              })}
            </div>
          </div>
        </FilterDropdown>
        {tempFilter?.isForRent && (
          <FilterDropdown
            label={t('Rental Frequency')}
            sectionHeight={130}
            isOpen={filterState.isFrequencyOpen}
            setIsOpen={() => toggleDropdown('isFrequencyOpen')}
          >
            <FilterCheckbox
              title={t('Yearly')}
              label={t('Yearly contract')}
              isChecked={selectedFrequency.includes(YEARLY_CONTRACT)}
              check={() => handleFrequencyCheckboxChange(YEARLY_CONTRACT)}
            />
            <FilterCheckbox
              title={t('Monthly')}
              label={t('Monthly contract')}
              isChecked={selectedFrequency.includes(MONTHLY_CONTRACT)}
              check={() => handleFrequencyCheckboxChange(MONTHLY_CONTRACT)}
            />
            <hr className="border-gray-200 py-1" />
            <FilterCheckbox
              title={t('Daily')}
              label={t('Daily rental available')}
              isChecked={tempFilter?.hasDailyRental}
              check={handleDailyCheckboxChange}
            />
          </FilterDropdown>
        )}
        <div className=" w-full absolute  bottom-0 sm:bottom-8 flex flex-grow border  bg-white border-transparent border-t-gray-100 pt-2 pb-8">
          <FilterSearchBtn
            resetFilter={resetFilter}
            handleSearch={handleShowResults}
            count={count}
            setIsFilterOpen={setIsFilterOpen}
          />
        </div>
      </div>
    </FilterContainer>
  );
};

export default MarketFilter3;
