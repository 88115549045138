import React from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { useTranslation } from 'react-i18next';

interface LocationSearchProps {
  locationString: string;
  setLocationString: any;
  label: string;
  // { city: ''; country: ''; latitude: 0; longitude: 0, postalCode }
  setLocationObject?: (loc: any) => void;
  setFormattedMainText?: (formattedMainText: string) => void;
}

const LocationSearch: React.FC<LocationSearchProps> = ({
  locationString,
  setLocationString,
  label,
  setLocationObject,
  setFormattedMainText,
}) => {
  const { t } = useTranslation();

  const handleChange = (description: string) => {
    setLocationString(description);
  };

  const handleSelect = async (searchedCity: string) => {
    try {
      const results = await geocodeByAddress(searchedCity);

      const addressComponents = results[0].address_components;
      let componentCity = '';
      let componentCountry = '';
      let componentPostalCode = '';

      // Loop through address components to find the city, country, and postal code
      addressComponents.forEach((component) => {
        const componentType = component.types[0];

        if (
          componentType === 'locality' ||
          componentType === 'sublocality' ||
          componentType === 'sublocality_level_1' ||
          componentType === 'administrative_area_level_1' ||
          componentType === 'postal_town'
        ) {
          componentCity = component.long_name;
        }

        if (componentType === 'country') {
          componentCountry = component.short_name;
        }

        if (componentType === 'postal_code') {
          componentPostalCode = component.long_name;
        }
      });

      const { lat, lng } = await getLatLng(results[0]);
      const locationObject = {
        city: componentCity,
        country: componentCountry,
        postalCode: componentPostalCode,
        latitude: lat,
        longitude: lng,
      };

      if (setLocationObject) {
        setLocationObject(locationObject);
      }

      console.log('Success:', location);
    } catch (error) {
      console.error('Error:', error);
    }

    // setCity(city);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, suggestions: any[]) => {
    if (event.key === 'Enter' && suggestions.length > 0) {
      handleSelect(suggestions[0].description);
    }
  };

  return (
    <PlacesAutocomplete
      value={locationString}
      googleCallbackName="myCallbackFunc" // component will initialize after the browser has finished downloading google script.
      // searchOptions={{ types: ['(cities)'] }}
      // searchOptions={{ componentRestrictions: { country: 'th' } }}
      onChange={handleChange}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className="w-full">
          <label className="block text-sm sm:text-md lg:text-md font-normal">{t(label)}</label>
          <div className="relative rounded-xl ">
            <div className="flex items-center">
              <img alt="pin" src="/images/icons/pin-card.svg" className="w-5 h-5 " />
              <input
                {...getInputProps({
                  placeholder: t('search a location'),
                  className:
                    'w-full border-none outline-none focus:outline-none focus:ring-0 text-sm',
                  onKeyDown: (event) => handleKeyDown(event, suggestions as any[]), // Add this line
                })}
              />
            </div>
            <div className="autocomplete-dropdown-container absolute top-12 z-50 shadow-xl  px-4 space-y-3 w-full bg-white">
              {loading && <div>{t('Loading...')}</div>}
              {suggestions.map((suggestion) => {
                console.log(suggestion);

                const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? {
                      backgroundColor: '#f2fdfb',
                      padding: '2px',
                      cursor: 'pointer',
                    }
                  : { backgroundColor: '#ffffff', padding: '2px', cursor: 'pointer' };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                      onClick: () => {
                        if (setFormattedMainText) {
                          setFormattedMainText(suggestion.formattedSuggestion.mainText);
                        }
                        handleChange(suggestion.description);
                      },
                    })}
                    key={suggestion.description}
                  >
                    <div>{suggestion.formattedSuggestion.mainText}</div>
                    <span
                      title={suggestion.formattedSuggestion.secondaryText}
                      className="text-gray-400 pt-1 text-xs line-clamp-1"
                    >
                      {suggestion.formattedSuggestion.secondaryText}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default LocationSearch;
