import React from 'react';
import ReactDOM from 'react-dom';
// import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import './i18n';
import { AuthProvider } from './contexts/auth';
import { PropertyProvider } from './contexts/property';
import { ScrollProvider } from './contexts/scroll';
import { InterProvider } from './contexts/internationalization';
import { GlobalProvider } from './contexts/global';
import { HuntProvider } from './contexts/hunt';
import { BusinessProvider } from './contexts/business';
import { ChatProvider } from './contexts/chat';
import ErrorBoundary from './ErrorBoundary/ErrorBoundary';
import { BrowserRouter } from 'react-router-dom';
// import register from './registerServiceWorker';
import { GoogleOAuthProvider } from '@react-oauth/google';

// TODO: this implementation use react17 to solve visual glitch in the ViewMap component.
// need to switch once the bug is fixed in google-map-react library
// refer to:   // https://github.com/google-map-react/google-map-react/issues/1143

// const root = createRoot(document.getElementById('root') as HTMLElement);
// root.render(
//   // <React.StrictMode>
//   <ErrorBoundary>
//     <BrowserRouter>
//       <AuthProvider>
//         <PropertyProvider>
//           <ScrollProvider>
//             <App />
//           </ScrollProvider>
//         </PropertyProvider>
//       </AuthProvider>
//     </BrowserRouter>
//   </ErrorBoundary>
//   // </React.StrictMode>
// );

ReactDOM.render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}>
    <BrowserRouter>
      <ErrorBoundary>
        <InterProvider>
          <AuthProvider>
            <BusinessProvider>
              <HuntProvider>
                <GlobalProvider>
                  <PropertyProvider>
                    <ScrollProvider>
                      <ChatProvider>
                        <App />
                      </ChatProvider>
                    </ScrollProvider>
                  </PropertyProvider>
                </GlobalProvider>
              </HuntProvider>
            </BusinessProvider>
          </AuthProvider>
        </InterProvider>
      </ErrorBoundary>
    </BrowserRouter>
  </GoogleOAuthProvider>,
  document.getElementById('root')
);

// register();
